<template>
<v-app>
    <v-dialog v-model="showDialogCourier" max-width="1000px">
        <v-card v-if="showDialogCourier">
            <v-card-title>
                <span>Cambiar Courier a Cola de pedidos ({{ordersQueue.length}})</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-chip-group column show-arrows >
                            <v-chip
                            v-for="(orderSelected,index) in ordersQueue"
                            :key="index"
                            >
                            {{ orderSelected.order_reference }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="order_carrier"
                            :items="carrierList"
                            :rules="[v => !!v || 'Debes elegir un Courier']"
                            label="Courier a aplicar"
                            required
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox v-model="forceRegenerateLabel" label="Forzar generar etiqueta si el pedido ya tiene un envío asociado?" > Forzar generar etiqueta si el pedido ya tiene un envío asociado? </v-checkbox>
                    </v-col>
                </v-row>
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialogCourier = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialogCourier: false,
            store_id: undefined,
            current_state: undefined,
            orderStatesList: [],
            ordersQueue: [],
            carrierList: [],
            order_carrier: undefined,
            forceRegenerateLabel: false,
            loading: true
        }
    },
    created(){
        
        this.$parent.$on('openDialogCambiarCourierMasivo', (datos) => {
            if(datos.length){
                this.ordersQueue = datos;
                this.store_id = datos[0].store_id;
                // Fetch Lista de estados
              // this.fetchData(); // en fetcData se setea el showDialogCourier = true
                this.fetchCouriers();
                this.forceRegenerateLabel = false;

                //this.showDialogCourier = true;
            }
            
        });
    },
    methods: {
        sendForm(){
            if(this.order_carrier){
                this.loading = true;
                var vm = this;
                this.axios({
                    url: 'orders/order/bulk-update-carrier',
                    method: 'POST',
                    data: {
                        orders: this.ordersQueue.map(e => e.id),
                        carrier: this.order_carrier,
                        forceregeneratelabel: this.forceRegenerateLabel
                    }
                }).then( () => {
                    vm.showDialogCourier = false;
                    vm.loading = false;
                    vm.$emit('success', "En los próximos segundos se actualizarán los couriers de las órdenes seleccionadas");
                }).catch( () => {
                    //console.log(error);
                });
            }
            
        },
        // sendFormCarrier()
        // {
        //     var vm = this;
        //     this.axios({
        //         url: 'orders/'+this.orden_id+'/carrier',
        //         method: 'POST',
        //         data: {
        //             carrier: this.order_carrier
        //         }
        //     }).then( () => {
        //         vm.$bvToast.toast('Estado actualizado correctamente', {
        //             title: `Información`,
        //             variant: 'success',
        //             solid: true,
        //             toaster: 'b-toaster-bottom-center'
        //         });
        //         vm.fetchData();
        //     }).catch( (error) => {
        //         console.log(error);
        //     });
        // },
        // fetchData(){
        //     var vm = this;
        //     this.axios({
        //         url: 'orders/bulk-update-states/data_select_input'
        //     }).then( response => {
        //         vm.orderStatesList = response.data;
        //         vm.showDialogCourier = true; 
        //     }).catch( (error) => {
        //         console.log(error);
        //     });
        // },
        fetchCouriers() {
            var vm = this;
            this.axios({
                method: "GET",
                url: "shippings/couriers/data_select_input",
            })
            .then((response) => {
            vm.carrierList = response.data;
            vm.carrierList.push({ text: "Automático", value: null });
            this.showDialogCourier = true;
            this.loading = false;
            })
            .catch((error) => {
            console.log(error);
            });
        },
    }
}
</script>